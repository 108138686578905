<template>
  <div>
    <b-container fluid class="content vip_sales">
      <b-row>
        <b-col>
          <b-card>
            <b-form>
              <el-row :gutter="10">
                <el-col :span="4">
                  <select class="form-control" v-model="cur_LocationID">
                    <option value="">{{ $t("Select Store") }}</option>
                    <option
                      v-for="(dt, idx) in storelist"
                      :key="idx"
                      :value="dt.LocationID"
                    >
                      {{ dt.LocationID }} - {{ dt.LocationName }}
                    </option>
                  </select>
                </el-col>
                <el-col :span="4">
                  <b-input
                    placeholder="VIPNo"
                    v-model="cur_CustomerNo"
                  ></b-input>
                </el-col>
                <el-col :span="4">
                  <b-input
                    placeholder="Phone"
                    v-model="cur_Cell"
                  ></b-input>
                </el-col>
                <el-col :span="4">
                  <base-input addon-left-icon="ni ni-calendar-grid-58">
                    <flat-picker
                      slot-scope="{ focus, blur }"
                      :placeholder="$t('Start Date')"
                      @on-open="focus"
                      @on-close="blur"
                      :config="picker_config"
                      class="form-control datepicker"
                      v-model="cur_FromCreateDate"
                    >
                    </flat-picker>
                  </base-input>
                </el-col>
                <el-col :span="4">
                  <base-input addon-left-icon="ni ni-calendar-grid-58">
                    <flat-picker
                      slot-scope="{ focus, blur }"
                      :placeholder="$t('End Date')"
                      @on-open="focus"
                      @on-close="blur"
                      :config="picker_config"
                      class="form-control datepicker"
                      v-model="cur_ToCreateDate"
                    >
                    </flat-picker>
                  </base-input>
                </el-col>
                <el-col :span="4" class="text-center">
                  <el-button
                    type="primary"
                    class="mt-2"
                    icon="el-icon-search"
                    size="small"
                    @click="get_data_list(0)"
                    >{{ $t("Search") }}</el-button
                  >
                </el-col>
                <el-col :span="4" class="text-center">
                  <section>
                    <xlsx-workbook>
                      <xlsx-sheet
                        :collection="sheet.data"
                        v-for="sheet in sheets"
                        :key="sheet.name"
                        :sheet-name="sheet.name"
                      />
                      <xlsx-download :filename="getReportName('VIP_Reload', 'xlsx')">
                        <el-button
                          type="primary"
                          class="mt-2"
                          icon="el-icon-document"
                          size="small"
                          >{{ $t("Export") }}</el-button
                        >
                      </xlsx-download>
                    </xlsx-workbook>
                  </section>
                </el-col>
              </el-row>
            </b-form>
          </b-card>
          <b-card>
            <div class="p-0" v-if="datalist">
              <el-row :gutter="20">
                <el-col :span="24"><h2>VIP Reload Report</h2></el-col>
              </el-row>
              <el-table stripe class="table-responsive table" header-row-class-name="thead-light" :data="datalist" show-summary :summary-method="getSummaries" @row-click="row_clicked_event">
                <el-table-column :label="$t('Store')" prop="StoreName"></el-table-column>
                <el-table-column :label="$t('VIP No')" prop="CardNo"></el-table-column>
                <el-table-column :label="$t('Phone')" prop="Phone"></el-table-column>
                <el-table-column :label="$t('Name')" prop="Name"></el-table-column>
                <el-table-column :label="$t('Time')" prop="DateTime"></el-table-column>
                <el-table-column :label="$t('Order')" prop="OrderNo"></el-table-column>
                <el-table-column :label="$t('Amount')" prop="Amount"></el-table-column>
                <el-table-column :label="$t('Balance')" prop="Balance"></el-table-column>
                <el-table-column :label="$t('Type')" prop="PaymentIDs"></el-table-column>
              </el-table>
            </div>
            <div class="p-3" v-else>
              {{ $t("No Records") }}
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>
    <vue-element-loading
      :active="showloading"
      spinner="bar-fade-scale"
      color="#FF6700"
      duration="2.0"
      background-color="rgba(255, 255, 255, .5)"
      size="100"
    />
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Button,
  Pagination,
  Collapse,
  CollapseItem,
  Row,
  Col,
} from "element-ui";
import SearchAutocomplete from "../Components/SearchAutocomplete";
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
import VueElementLoading from "vue-element-loading";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Row.name]: Row,
    [Col.name]: Col,
    [Pagination.name]: Pagination,
    VueElementLoading,
    flatPicker,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    // SearchAutocomplete,
  },
  data() {
    return {
      cur_LocationID: "",
      cur_CustomerIDn: "",
      cur_Search: "",
      cur_CustomerNo: "",
      cur_Cell: "",
      cur_FromCreateDate: "",
      cur_ToCreateDate: "",
      showloading: false,
      todaystr: "",

      storelist: null,
      order_total: 0,

      datalist: null,

      sheets: [{ name: "Sheet1", data: [[1, 2, 2], [1, 2, 2, 2],[],[1, 2, 2, 2 ]] }],
      // sheets: [{ name: "Sheet1", data: [{ a: 1, b: 2, c: 2 },{ a: 1, b: 2, c: 2, d: 2 },{},{ a: 1, b: 2, c: 2, d: 2 }] }],

      picker_config: {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'Y-m-d',
        maxDate: 'today', // New Date();  YYYY-MM-DD HH:MM
      },

      delay_timer: null,

      token: "",
      user_id: 0,
    };
  },
  watch: {
  },
  computed: {
    apiBaseUrl: function () {
      return axios.defaults.baseURL + "/Api";
    },
  },
  methods: {
    row_clicked_event(row, event, column) {
      // console.log("row_clicked_event", row, event, column); //XXXXXXXXXXXXXXXXX
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Total';
        } else if (index != 6) {
          sums[index] = '';
        } else {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => Number.isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!Number.isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
          } else {
            sums[index] = "N/A";
          }
        }
      });
      return sums;
    },
    fillup_sheet() {
      if (this.datalist.length <= 0) {
        return;
      }

      var dataarr = [];
      dataarr.push([
        'VIP Topup Report('+this.cur_FromCreateDate+' - '+this.cur_ToCreateDate+')',
      ]);
      dataarr.push([
        this.$t('Store'),
        this.$t('VIP No'),
        this.$t('Phone'),
        this.$t('Name'),
        this.$t('Time'),
        this.$t('Order'),
        this.$t('Amount'),
        this.$t('Balance'),
        this.$t('Type'),
      ]);
      let amount = 0;
      let balance = 0;
      for (var i=0; i<this.datalist.length; i++) {
        dataarr.push([
          this.datalist[i].StoreName,
          this.datalist[i].CardNo,
          this.datalist[i].Phone,
          this.datalist[i].Name,
          this.datalist[i].DateTime,
          this.datalist[i].OrderNo,
          this.datalist[i].Amount,
          this.datalist[i].Balance,
          this.datalist[i].PaymentIDs,
        ]);
        amount += parseFloat(this.datalist[i].Amount);
        balance += parseFloat(this.datalist[i].Balance);
      }
      dataarr.push([
        'Total',
        '',
        '',
        '',
        '',
        '',
        amount.toFixed(2),
      ]);

      var sheetname = (this.sheets[0].name == 'sheet1')?'defalut':'sheet1'
      this.sheets = [{ name: sheetname, data: dataarr}];
    },
    remove_dash(myString) {
      return myString.replace(/-/g, "");
    },
    getReportName(reportName , reportType) {
      if (!this.datalist) {
        return "";
      }
      return reportName + '_' + this.cur_FromCreateDate + '_' + this.cur_ToCreateDate + '.' + reportType;
    },
    async get_data_list(init) {
      this.showloading = true;
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("init", init);

      if (this.cur_LocationID) {
        bodyFormData.append("StoreID", this.cur_LocationID);
      }
      if (this.cur_CustomerIDn) {
        bodyFormData.append("CustomerIDn", this.cur_CustomerIDn);
      } else if (this.cur_Search) {
        bodyFormData.append("Search", this.cur_Search);
      }
      if (this.cur_CustomerNo) {
        bodyFormData.append("CustomerNo", this.cur_CustomerNo);
      }
      if (this.cur_Cell) {
        bodyFormData.append("Cell", this.cur_Cell);
      }
      if (this.cur_FromCreateDate) {
        bodyFormData.append("FromCreateDate", this.cur_FromCreateDate);
      } else {
        bodyFormData.append("FromCreateDate", this.todaystr);
      }
      if (this.cur_ToCreateDate) {
        bodyFormData.append("ToCreateDate", this.cur_ToCreateDate);
      } else {
        bodyFormData.append("ToCreateDate", this.todaystr);
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/POS/Vip/reload",
        data: bodyFormData,
      })
        .then(function (response) {
          that.showloading = false;
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              if (init) {
                that.storelist = rt.data.locations;
              }
              that.datalist = rt.data.datalist;
              that.fillup_sheet();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.showloading = false;
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      var today = new Date();
      this.todaystr = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + String(today.getDate()).padStart(2, '0');

      this.cur_FromCreateDate = this.todaystr;
      this.cur_ToCreateDate = this.todaystr;

      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      return this.get_data_list(1);
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.vip_sales {
  padding-top: 40px;
}
.vip_sales .search-button {
  margin-top: 2rem;
}
.vip_sales .select_text {
  margin-left: -1rem;
}
.vip_sales .card .table th {
  padding: 0rem;
}
.vip_sales .card .table td {
  padding: 0rem;
}
.vip_sales .picimg {
  padding-left: 3rem;
  max-height: 6rem;
}
.vip_sales .el-collapse-item__header {
  background-color: #f0f0f0;
  font-size: 1.2rem;
  padding-left: 1rem;
}
.vip_sales .el-collapse-item__content {
  padding-bottom: 0.5rem;
}
.vip_sales #mymodalclass .modal-title {
  font-size: 2rem !important;
}
.vip_sales #mymodalclass .modal-body {
  padding-top: 0;
  padding-bottom: 0;
}
.vip_sales .form-group {
  margin-bottom: 0.5rem;
}
.vip_sales .top_line {
  height: 1px;
  background: black;
}
.vip_sales .order_heads {
  background-color: #cccccc;
}
</style>
